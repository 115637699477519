import http from "@/utils/request";

// 请求首页数据

export const getData = () => {
    //返回一个promise对象
    return http.get("/home/getData");
}

export const getUser = (params) => {
    //返回一个用户列表
    return http.get("web/user/getUserList",params);
}

export const getViewAbleUserList = (params) => {
    //获取可查看数据的用户列表
    return http.get("web/user/getViewAbleUserList",params);
}

export const deleteVideAbleUser = (params) =>{
    return http.get("web/user/relation/deleteUserRelation",params)
}

export const saveUserRelation = (data) => {
    //保存
    return http.post("web/user/relation/saveUserRelation",data);
}

export const addUser = (data) => {
    //添加用户
    return http.post("web/user/addUser",data);

}

export const delUser = (path,params) => {
    //删除用户
    return http.get(`${path}?${params}`);
}

export const editUser = (data) => {
    //编辑用户
    return http.post("web/user/updateUser",data);
}
export const getMenu = (path,params) =>{
    return http.get(`${path}?${params}`)
}

export const saveUserDetail = (data) => {
    //保存用户详情信息
    return http.post("/web/user/detail/saveUserDetail",data);
}

export const getUserDetail = (path, params) => {
    //获取用户详情信息
    return http.get(`${path}?${params}`);
}

// 通过手机号码获取用户信息
export const getUserInfoByPhone = (path, params) => {
    return http.get(`${path}?${params}`);
}

// 通过用户id获取用户信息
export const getUserInfoById = (path, params) => {
    return http.get(`${path}?${params}`);
}

export const login = (data) =>{
    return http.post("/web/manage/login",data)
}

//设置用户密码
export const setPassword = (path, params) => {
    return http.get(`${path}?${params}`);
}

//检验验证码
export const checkVerifyCode = (path,params) => {
    return http.get(`${path}?${params}`)
}
//注册
export const register = (data) => {
    return http.post("/web/manage/register",data)
}
export const getEquipmentList = (data) => {
    //查询设备列表
    return http.get("/web/equipment/getEquipmentList",data);
}

// 编辑设备
export const editEquipment = (data) => {
    return http.post("/web/equipment/updateEquipment",data);
}

// 删除设备
export const delEquipment = (path,params) => {
    return http.get(`${path}?${params}`);
}

// 添加设备
export const addEquipment = (data) => {
    return http.post("/web/equipment/addEquipment",data);
}

//获取当前设备的产品信息列表
export const selEquipmentProductList =(data)=> {
    return http.get("/web/equipment/product/selEquipmentProductList",data)
}
//更新设备的产品信息
export const updateEquipentProduct = (data) => {
    return http.post("/web/equipment/product/updateEquipmentProduct",data);
}
//删除设备绑定的产品
export const delEquipmentProduct = (data) => {
    return http.get("/web/equipment/product/delEquipmentProduct",data)
}
//保存设备保定的产品信息
export const addEquipmentProduct = (data) => {
    return http.post("/web/equipment/product/addEquipmentProduct",data)
}

//获取验证码
export const getCode = (path,params) => {
    return http.get(`${path}?${params}`);
}
                                   // 角色管理
export const getRoleList = () => {
    //查询角色列表
    return http.get("/web/role/getRoleList");
}
export const getRoleMenuListByRoleId= (path,params) => {
    //根据角色id查询角色菜单列表
    return http.get(`${path}?${params}`);
}
export const saveRoleMenu = (data) => {
    //保存角色菜单
    return http.post("/web/roleMenu/saveRoleMenu",data);
}

                                   //数据统计相关接口
export const getDataReportAdmin = () => {
    //数据统计相关接口
    return http.get("web/dataReport/getDataReportAdmin");
}
export const getEquipmentRankingAdmin = () => {
    //设备排行榜
    return http.get("web/dataReport/getEquipmentRankingAdmin");
}
export const getSalePriceRankingAdmin = () => {
    //销售额排行榜
    return http.get("web/dataReport/getSalePriceRankingAdmin");
}
export const getSaleRecordAdmin = () => {
    //销售记录
    return http.get("web/dataReport/getSaleRecordAdmin");
}
export const selectSaleRecordList = (params) => {
    //销售记录列表
    return http.get("web/juiceRecords/selectSaleRecordList",params);
}

export const exportSaleRecordList = (params) => {
    //导出销售记录信息
    return http.get("web/juiceRecords/exportJuiceRecord", { params: params, responseType: 'arraybuffer' });
}

                                   //系统设置
                                   //角色相关
export const serachMenuList = (params) => {
    //查询菜单列表
    return http.get("/web/menu/getMenuList",params);
}
export const editMenu = (data) => {
    //编辑菜单
    return http.post("/web/menu/editMenu",data);
}
export const addMenu = (data) => {
    //添加菜单
    return http.post("/web/menu/addMenu",data);
}
export const delMenu = (path, params) => {
    //删除菜单
    return http.get(`${path}?${params}`);
}
//系统开关列表
export const getSystemSwitchList = () => {
    return http.get("web/switchList/getSwitchList");
}
//修改开关
export const updateSwitch = (data) => {
    return http.post("web/switchList/updateSwitch",data);
}


//        支付
export const payTest = () => {

    return http.get("app/pay/initiatePay");
}
//  微信认证回调
export const wxPay = (path, params) => {
    return http.get(`${path}?${params}`);
}

export const getJuiceRecordDetail = (path, params) => {
    //获取果汁记录详情
    return http.get(`${path}?${params}`);
}

export const createOrderTraceCode = (path, params )=> {
    //生成订单追踪码
    return http.get(`${path}?${params}`);
}


export const getSubAccountMsg = ( path, params) => {
    //获取分账信息
    return http.get(`${path}?${params}`);
}
//根据机器id获取当前机器的收益比例
export const getIncomeRatioListByEquipmentId = (params) => {
    return http.get("web/incomeRatio/getIncomeRatioListByEquipmentId",params)
}
//保存收益分配信息
export const saveIncomeRatioList = (data) => {
    return http.post("web/incomeRatio/saveIncomeRatioList",data)
}


//果汁记录

export const deleteJuiceRecord = (path, params) => {
    return http.get(`${path}?${params}`);
}


//获取产品分类列表
export const getProductCateList = (params) => {
    return http.get("web/product/type/selProductCateList",params);
}

//添加产品分类
export const addProductCate = (data) => {
    return http.post("web/product/type/addProductType",data)
}

//修改产品分类
export const updateProductCate = (data) => {
    return http.post("web/product/type/updateProductType",data)
}

//获取产品列表
export const getProductList = (params) => {
    return http.get("web/product/list/selProductList",params);
}
//添加产品
export const addProduct = (data) => {
    return http.post("web/product/list/addProduct", data);
}
//修改产品
export const updateProduct = (data) => {
    return http.post("web/product/list/updateProduct",data)
}


                                //产品口味

//获取产品列表
export const getFlavorsList = (params) => {
    return http.get("web/flavors/list/selFlavorsList",params);
}
//添加产品口味
export const addFlavors = (data) => {
    return http.post("web/flavors/list/addFlavorsList",data)
}
//修改产品口味
export const updateFlavors=(data) => {
    return http.post("web/flavors/list/updateFlavorsList",data)
}
//删除产品口味
export const delFlavors = (path, params) => {
    return http.get(`${path}?${params}`);
}


                                 //地区
//获取地区信息
export const getAreaList = (params) => {
    return http.get("web/area/selAreaList",params)
}


//上传文件
export const fileUpload =(data) => {
    return http.post("web/fileUpload/uploadFile",data)
}
                                 //订单
export const getOrderList = (params) => {
    return http.get("web/order/selOrderList",params)
}

export const orderProductRefund = (params) =>{
    return http.get("web/order/initiateRefund",params)
}

export const deleteOrder = (params) =>{
    return http.get("web/order/deleteOrder",params)
}

                                     //消息通知
//获取消息通知列表
export const getNotificationList = (params) => {
    return http.get("web/notification/getNotificationList",params)
}
//添加消息通知
export const addNotification = (data)=>{
    return http.post("web/notification/addNotification",data)
}
//修改消息通知
export const updateNotification = (data)=>{
    return http.post("web/notification/updateNotification",data)
}

                                     //设备状态
//添加设备状态
export const addDeviceStatus =(data)=>{
    return http.post("web/equipmentOperationStatus/addEquipmentOperationStatus",data)
}
//修改设备状态
export const updateDeviceStatus =(data)=>{
    return http.post("web/equipmentOperationStatus/updateEquipmentOperationStatus",data)
}
//获取设备状态列表
export const getDeviceStatusList = (params) => {
    return http.get("web/equipmentOperationStatus/getEquipmentOperationStatusList",params)
}
                                     //设备运行记录
//获取设备状态列表
export const getEquipmentOperationRecord = (params) => {
    return http.get("web/equipmentOperationRecord/getEquipmentOperationRecord",params)
}
